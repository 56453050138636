import { render, staticRenderFns } from "./CmsCategory.vue?vue&type=template&id=27764acb&scoped=true&"
import script from "./CmsCategory.vue?vue&type=script&lang=js&"
export * from "./CmsCategory.vue?vue&type=script&lang=js&"
import style0 from "./CmsCategory.vue?vue&type=style&index=0&id=27764acb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27764acb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHeading: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseHeading.vue').default,BaseImage: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseImage.vue').default,BaseHtmlCode: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/htmlContent/BaseHtmlCode.vue').default})
