import { render, staticRenderFns } from "./CmsProducts.vue?vue&type=template&id=5c842e93&"
import script from "./CmsProducts.vue?vue&type=script&lang=js&"
export * from "./CmsProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductTile: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/product/ProductTile.vue').default,BaseCarousel: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/carousel/BaseCarousel.vue').default,BaseSheet: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseSheet.vue').default,BaseTabsItem: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/tabs/BaseTabsItem.vue').default,BaseTabs: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/tabs/BaseTabs.vue').default})
